export default {
  data() {
    return {
      confirmationHint: 'Must match password',
    };
  },
  methods: {
    validate() {
      if (this.password === this.passwordConfirmation) {
        this.confirmationHint = 'Nice!';
      } else {
        this.confirmationHint = 'Must match password.';
      }
    },
  },
  watch: {
    passwordConfirmation() {
      this.validate();
    },
    password() {
      if (this.passwordConfirmation) this.validate();
    },
  },
};
