<template>
  <main class="page" :class="parentOnboarding ? '' : 'page--provider'">
    <div class="logo register-logo" :class="darkLogo ? 'logo--dark' : 'logo--white'"></div>

    <div class="onboarding__wrapper">
      <div class="onboarding__form">
        <illustration v-if="illustration" :name="illustration" class="onboarding__illustration"/>
        <steps
            v-if="showProgress && totalSteps"
            :current-step="currentStep"
            :total-steps="totalSteps"
        />
        <slot></slot>
        <footer class="onboarding__footer">
          <div v-if="showNextButton || showPrevButton"
            :class="parentOnboarding ? 'nextPrevButtons' : 'nextBtn'"
          >
            <system-button
              style-type="secondary"
              v-if="showPrevButton"
              @click="prevStep"
            >
              Back
            </system-button>
            <div class="internal-step-counter" v-if="showInternalStepsCounter">
              {{ currentInternalStep }}/{{ totalInternalSteps }}
            </div>
            <system-button
              v-if="showNextButton"
              :disabled="!isNextButtonEnabled"
              @click="nextStep"
              data-cy="onboarding-next-btn"
            >
              {{ nextLabel || "Continue" }}
            </system-button>
            <slot name="footer"></slot>
          </div>
          <div v-if="currentStepIsSkippable" class="skipButton">
            <system-button
              style-type="clear"
              color="periwinkle"
              @click="skip"
            >
              {{ skipLabel }}
            </system-button>
          </div>
        </footer>
      </div>
    </div>
  </main>
</template>

<script>
import Illustration from '@components/illustration.vue';
import SystemButton from '@components/buttons/system-button.vue';
import Steps from '@components/progress/steps.vue';
import { kinsideLogo } from '@images';

export default {
  components: {
    Illustration, SystemButton, Steps,
  },
  props: {
    illustration: {
      type: String,
    },
    currentStep: {
      type: Number,
    },
    totalSteps: {
      type: Number,
    },
    showProgress: {
      type: Boolean,
    },
    isNextButtonEnabled: {
      type: Boolean,
    },
    currentStepIsSkippable: {
      type: Boolean,
    },
    skipLabel: {
      type: String,
    },
    nextLabel: {
      type: String,
    },
    showNextButton: {
      type: Boolean,
      default: false,
    },
    showPrevButton: {
      type: Boolean,
      default: false,
    },
    showInternalStepsCounter: {
      type: Boolean,
      default: false,
    },
    currentInternalStep: {
      type: Number,
      default: 1,
    },
    totalInternalSteps: {
      type: Number,
      default: 1,
    },
    parentOnboarding: {
      type: Boolean,
      default: false,
    },
    darkLogo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    logoStyle() {
      return { 'background-image': `url(${kinsideLogo.light})` };
    },
  },
  methods: {
    prevStep() {
      this.$emit('prev-step');
    },
    nextStep() {
      this.$emit('next-step');
    },
    skip() {
      this.$emit('skip-step');
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-navigation);
  height: 100vh;
  min-height: 100%;
  overflow-y: scroll;
  background: var(--viridian-50);

  &--provider {
    background: var(--viridian-0);
    background-image: url('https://res.cloudinary.com/kinside/image/upload/v1669144304/app_assets/register-bg.svg');
    background-repeat: repeat-x;
    background-position: top 388px center;
  }
}

.onboarding {
  &__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: calc(var(--grid-unit) * 7) calc(var(--grid-unit) * 2);

    @media screen and (min-width: 768px) {
      padding: calc(var(--grid-unit) * 3);
    }

    @media screen and (min-width: 992px) {
      padding: calc(var(--grid-unit) * 5);
    }
  }

  &__form {
    position: relative;
    width: 100%;
    max-width: 472px;
    padding: calc(var(--grid-unit) * 2);
    background: var(--gray-0);
    border-radius: 24px 24px 16px 16px;

    @media screen and (min-width: 400px) {
      padding: calc(var(--grid-unit) * 4);
      padding-bottom: calc(var(--grid-unit) * 2);
      margin-top: 100px;
      border-radius: 32px 32px 24px 24px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .nextPrevButtons {
      justify-content: center;
      margin-top: calc(var(--grid-unit) * 2);
      margin-bottom: calc(var(--grid-unit) * 2);

      > * {
        margin-right: var(--grid-unit);

        &:last-child {
          margin-right: 0;
        }
      }

      .internal-step-counter {
        display: inline-block;
      }
    }

    .nextBtn {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;

      button {
        display: block;
        width: 100%;
        padding: 10px;
        margin: 0 auto;
      }
    }

    .skipButton {
      margin-top: var(--grid-unit);
    }
  }

  &__illustration {
    display: block;
    width: calc(var(--grid-unit) * 10.5);
    margin: 0 auto;

    @media screen and (min-width: 768px) {
      width: calc(var(--grid-unit) * 20);
      margin: -33% auto 16px;
    }
  }
}
</style>
