<template>
  <div id="provider-claim">
    <onboarding-template :darkLogo="true" :current-step="1"
      :total-steps="totalSteps" :show-progress="false"
      :is-next-button-enabled="enableNextStep" :show-next-button="currentStep !== 'createAccount'"
      :next-label="nextLabelText" @next-step="handleNextButton">
      <h4 class="title font-heading-30">{{ stepTitle }}</h4>
      <p class="mt-1 error-message" v-if="!!errorMessages.length">
        {{ errorMessages }}
      </p>
      <simple-facility-card v-if="showFacilityCard"
        :key="localFacility.id" :facility-data="facility"
        :is-claimable="true" :claimed="true" :claiming="true"
        :claim-disabled="true" class="facility-card" />
      <onboarding-step v-if="['locationInfo', 'locationContact'].includes(currentStep)">
        <template v-if="isContactInfo">
          <div class="contact">
            <p class="contact__title">Is this information up to date?</p>
            <div class="contact__info">
              <label class="label">Phone number</label>
              <span class="info">{{ localFacility.phone_number || '- -' }}</span>
            </div>
            <div class="contact__info">
              <label class="label">Email</label>
              <span class="info">{{ localFacility.email || '- -' }}</span>
            </div>
          </div>
          <div class="note">
            <p class="note__title">Why is this important?</p>
            <span>This information will be used to prove you are the owner of the business
              so make sure you have access to these accounts.</span><br><br>
            <i class="fa-light fa-hand-point-up"></i>
            <span>If this information is not correct, please reach out to support
              via chat or email (support@kinside.com) for help.</span>
          </div>
        </template>
        <p class="sub-title" v-else>
          In order to claim your profile on kinside, we need to verify you are the provider.
          It's free and only takes a minute.
        </p>
      </onboarding-step>
      <onboarding-step v-if="currentStep === 'createAccount'">
        <provider-register @submit="handleNextButton" :ccf-id="localFacility.id" />
      </onboarding-step>
      <onboarding-step v-if="currentStep === 'verifyMethod'">
        <verification-methods
          :phone-number="localFacility.phone_number"
          :email="localFacility.email"
          :verify-method="verifyMethod"
          @change="(val) => (verifyMethod = val)"/>
      </onboarding-step>
      <onboarding-step v-if="currentStep === 'verificationCode'">
        <verify-account
          @change="(val) => (codeEntered = val)"
          @resend-code="sendCode(true)"
          :code-entered="codeEntered"
          :email="verifyMethod === 'email' ? localFacility.email : undefined"
          :phoneNumber="localFacility.phone_number"
          :error="!!errorMessages.length"/>
      </onboarding-step>
      <onboarding-step v-if="['successMoreLocations', 'successViewProfile'].includes(currentStep)">
        <box class="m-b-48" color="viridian-light" faIcon="circle-check" :icnSolid="true">
          <p>You can now manage <span class="bold">{{ facilitiesName }}</span> on Kinside</p>
        </box>
        <div class="success-note" v-if="currentStep === 'successMoreLocations'">
          <p class="bold">Claim more locations!</p>
          <p>We found {{ moreFacilities?.length }} other locations
            in our system associated with your contact info.</p>
        </div>
        <div v-if="displayMoreFacilities">
          <simple-facility-card v-for="facility in moreFacilities"
            :key="facility.id"
            :facility-data="facility"
            :is-claimable="true"
            :claimed="facility.isClaimed"
            class="facility-card inner-margin"
            @claim-facility="claimFacility"
          />
        </div>
      </onboarding-step>
      <template v-slot:footer v-if="enableBtn">
        <system-button style-type="clear" @click="prevStep">
          {{ prevLabelText }}
        </system-button>
      </template>
      <template v-if="currentStep === 'createAccount'">
        <div class="login-container">
          <p>Already have a Kinside account?</p>
          <a href="#" class="login-link" @click.prevent="switchToLogin">Log in</a>
        </div>
      </template>
    </onboarding-template>
    <provider-login v-if="currentStep === 'createAccount'">
      <GoogleOAuth />
    </provider-login>
  </div>
</template>

<script>
import { axios } from '@utils';
import AuthMixin from '@profile/mixins/auth';
import OnboardingTemplate from '@components/onboarding/onboarding-template';
import OnboardingStep from '@components/onboarding/onboarding-step';
import VerificationMethods from '@components/onboarding/verification-methods';
import VerifyAccount from '@components/onboarding/verify-account';
import ProviderRegister from '@care-provider/components/register.vue';
import ProviderLogin from '@components/login.vue';
import SimpleFacilityCard from '@components/simple-facility-card/simple-facility-card.vue';
import SystemButton from '@components/buttons/system-button.vue';
import Box from '@components/box.vue';
import GoogleOAuth from '@components/google-o-auth.vue';

export default {
  name: 'profile-onboarding',
  components: {
    OnboardingTemplate,
    OnboardingStep,
    VerificationMethods,
    VerifyAccount,
    ProviderRegister,
    ProviderLogin,
    GoogleOAuth,
    SimpleFacilityCard,
    SystemButton,
    Box,
  },
  mixins: [AuthMixin],
  props: {
    facility: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      stepIndex: 0,
      verifyMethod: undefined,
      codeEntered: undefined,
      codeVerified: false,
      userEmail: undefined,
      hasMoreLocations: false,
      showLocations: false,
      moreFacilities: [],
      errorMessages: '',
      localFacility: { ...this.facility },
      csrfToken: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    };
  },
  steps: [
    'locationInfo',
    'locationContact',
    'createAccount',
    'verifyMethod',
    'verificationCode',
    'successMoreLocations',
    'successViewProfile',
  ],
  mounted() {
    this.userData();
  },
  methods: {
    async userData() {
      this.$store.dispatch('init');
      await this.$store.dispatch('isInitialized');
      this.userEmail = this.$store.state.user.current.email;
    },
    prevStep() {
      if (this.stepIndex === 0) return;
      this.errorMessages = '';
      if (this.skippableStep) {
        this.moreFacilities.forEach((f) => { this.$set(f, 'isClaimed', false); });
        this.stepIndex += 1;
        return;
      }
      this.stepIndex -= 1;
    },
    async handleNextButton(data = {}) {
      if (this.stepIndex === this.totalSteps) return;
      if (this.errorMessages.length) this.errorMessages = '';
      if (this.isContactInfo && !!this.userEmail?.length) this.stepIndex += 1;
      if (this.currentStep === 'successViewProfile') {
        if (!this.claimedFacilityIds.includes(this.localFacility.id)) {
          this.claimedFacilities.push(this.localFacility);
        }
        const count = this.claimedFacilities.length;
        const params = {
          signup_from: 'claim_profile',
          user: {
            onboarding_completed: true,
            onboarding_version: 4,
            number_of_locations: count,
            claimed_facilities: this.claimedFacilities.map((f) => f.id),
          },
        };
        await this.updateUser(params);
        return;
      }
      if (this.currentStep === 'createAccount') {
        this.userEmail = data.email || this.userEmail;
      }
      if (this.currentStep === 'verifyMethod') {
        await this.sendCode();
      }
      if (this.currentStep === 'verificationCode') {
        await this.verifyAccount();
        if (!this.codeVerified) return;
        if (!this.hasMoreLocations) this.stepIndex += 1;
      }
      if (this.currentStep === 'successMoreLocations' && this.hasMoreLocations && !this.showLocations) {
        this.showLocations = true;
        return;
      }
      this.stepIndex += 1;
    },
    switchModalToLogin() {
      this.$emit('navigate-to-login', true);
    },
    async sendCode(retry = false) {
      await axios.put(`/${retry ? 'request_new_verification_code' : 'send_code'}.json`, {
        email: (this.userEmail?.toLowerCase()),
        facility_phone_number: this.localFacility.phone_number,
        facility_email: this.localFacility.email,
        method: this.verifyMethod,
      })
        .then((response) => {
          this.errorMessages = '';
          if (!response.data.success) {
            this.errorMessages = response.data.message;
          }
        });
    },
    async verifyAccount() {
      await axios.put('/verify_user.json', { email: (this.userEmail?.toLowerCase()), verification_code: this.codeEntered })
        .then((response) => {
          if (response.data.success && response.status === 200) {
            this.codeVerified = true;
          } else if (response.data.message.length > 0) {
            this.codeEntered = undefined;
            this.codeVerified = false;
            this.errorMessages = response.data.message;
          }
        });
      if (this.codeVerified) {
        await this.checkMoreLocations();
      }
    },
    async checkMoreLocations() {
      await axios.get('/childcare/by_contact.json', {
        params:
        {
          email: (this.localFacility.email?.toLowerCase()),
          phone_number: this.localFacility.phone_number,
        },
      }).then((response) => {
        if (response.data.facilities?.length && response.status === 200) {
          this.moreFacilities = response.data.facilities?.filter(
            (f) => (this.localFacility.id !== f.id),
          );
          this.hasMoreLocations = !!this.moreFacilities?.length;
        }
      });
    },
    async updateUser(params) {
      await axios.put('/user.json', params)
        .then((response) => {
          if (response.status === 200) {
            window.location.href = '/';
          }
        });
    },
    claimFacility(facility) {
      const selectedFacility = this.moreFacilities.find((f) => f.id === facility.id);

      if (selectedFacility) {
        this.$set(selectedFacility, 'isClaimed', !selectedFacility?.isClaimed);
      }
    },
  },
  computed: {
    totalSteps() {
      return this.$options.steps.length;
    },
    currentStep() {
      return this.$options.steps[this.stepIndex];
    },
    showFacilityCard() {
      return !['verificationCode', 'successMoreLocations', 'successViewProfile'].includes(this.currentStep);
    },
    isContactInfo() {
      return this.currentStep === 'locationContact';
    },
    isVerifyMethod() {
      return this.currentStep === 'verifyMethod';
    },
    skippableStep() {
      return this.currentStep === 'successMoreLocations';
    },
    displayMoreFacilities() {
      return this.skippableStep && this.showLocations && this.moreFacilities?.length > 0;
    },
    claimedFacilities() {
      return this.moreFacilities.filter((f) => f.isClaimed);
    },
    claimedFacilityIds() {
      return this.claimedFacilities.map((f) => f.id);
    },
    facilitiesName() {
      const names = this.claimedFacilities.map((f) => f.name);
      names.push(this.localFacility.name);
      return names.join(', ');
    },
    enableNextStep() {
      if (this.currentStep === 'verifyMethod') {
        return !!this.verifyMethod?.length;
      }
      if (this.currentStep === 'verificationCode') {
        return this.codeEntered?.length === 5;
      }
      return true;
    },
    nextLabelText() {
      switch (this.currentStep) {
        case 'locationInfo':
          return 'Get verified';
        case 'verifyMethod':
          return 'Send';
        case 'verificationCode':
          return 'Go to my profile';
        case 'successMoreLocations':
          return this.showLocations ? 'Continue' : 'View locations';
        case 'successViewProfile':
          return 'View profile';
        default:
          return 'Continue';
      }
    },
    enableBtn() {
      const enableStep = !['locationInfo', 'verifyMethod'].includes(this.currentStep);
      const canClaimMore = this.currentStep === 'successViewProfile' ? !!this.moreFacilities?.length : true;
      return enableStep && canClaimMore;
    },
    prevLabelText() {
      switch (this.currentStep) {
        case 'successMoreLocations':
          return 'Skip for now';
        default:
          return 'Go back';
      }
    },
    stepTitle() {
      switch (this.currentStep) {
        case 'locationInfo':
        case 'locationContact':
          return 'Claim your business on Kinside';
        case 'createAccount':
          return 'Create your free account';
        case 'verifyMethod':
          return 'Choose a way to verify';
        case 'verificationCode':
          return 'Verification code sent';
        default:
          return 'Success!';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .page {
    position: unset;
  }
}

.error-message {
  color: var(--rust-40);
}

.bold {
  font-weight: 500;
}

.title {
  color: var(--viridian-50);
  text-align: center;
  margin-bottom: 1.5rem;
}

.sub-title {
  font-size: medium;
  text-align: center;
  font-weight: 400;
  padding: 1rem 0 2rem;
}

.contact {
  margin-top: 1.5rem;
  border-radius: var(--grid-unit);
  border: 1px solid #dbdbdb;
  padding: calc(var(--grid-unit) * 2);
  font-size: 16px;

  &__title {
    font-weight: 500;
    font-family: var(--sans-serif);
  }

  &__info {
    .label {
      display: block;
      margin-bottom: 5px;
      color: var(--gray-40);
    }
  }

  &__info:not(:last-child) {
    margin-bottom: 10px;
  }
}

.note {
  margin-top: 1.5rem;
  color: var(--gold-80);
  background-color: var(--gold-0);
  border: 1px solid var(--gold-50);
  border-radius: var(--grid-unit);
  padding: calc(var(--grid-unit) * 2);
  font-size: calc(var(--grid-unit) * 2);
  font-weight: 400;

  &__title {
    font-weight: 500;
    font-size: 16px;
    font-family: var(--sans-serif);
    color: var(--gold-80);
  }
}

.login-container {
  text-align: center;

  .login-link {
    display: inline-block;
    margin-top: 5px;
    font-size: 16px;
  }
}

.success-note {
  padding: calc(var(--grid-unit) * 2);
  margin-bottom: 2rem;
  background-color: var(--periwinkle-0);
  color: var(--periwinkle-60);
  border-radius: var(--radius);
  border: 1px solid var(--periwinkle-15);
}

.inner-margin:not(:last-child) {
    margin-bottom: var(--grid-unit);
  }
</style>
