<template>
  <div
      class="simple-facility-card"
      :class="isClaimable || isEditable ? 'simple-facility-card--claimable' : ''"
  >
    <facility-photo
      class="simple-facility-card__image"
      :facility-id="facilityId"
      :photo-url="photoUrl"
    />
    <div class="simple-facility-card__data">
      <h6>{{ facilityData.name }}</h6>
      <p class="text-color-50 font-body-small">{{ facilityData.address }}</p>
      <p class="text-color-50 font-body-small"
         v-if="isClaimable && !!facilityData.licenseNumber">
        License # {{ facilityData.licenseNumber }}
      </p>
    </div>
    <div v-if="isFavorited" class="simple-facility-card__fav">
      <span class="fas fa-heart"></span>
    </div>
    <div v-if="isClaimable" class="simple-facility-card__claim">
      <!-- eslint-disable vue/no-v-text-v-html-on-component -->
      <system-button
          v-if="showClaimBtn"
          @click="$emit('claim-facility', facilityData)"
          :style-type="buttonStyle"
          :disabled="claimDisabled"
          v-html="claimText"
      />
      <!-- eslint-enable vue/no-v-text-v-html-on-component -->
      <a
          v-if="showUnclaimLink"
          href="#"
          @click.prevent="$emit('claim-facility', facilityData)"
      >
        Unclaim
      </a>
    </div>
    <div v-if="isEditable" class="simple-facility-card__edit">
      <a
          href="#"
          @click.prevent="$emit('edit-facility', facilityData)"
      >
        <i class="fal fa-pencil"/>
      </a>
      <a
          href="#"
          @click.prevent="$emit('delete-facility', facilityData)"
      >
        <i class="fal fa-trash"/>
      </a>
    </div>
  </div>
</template>

<script>
import FacilityPhoto from '@components/facility-photo.vue';
import SystemButton from '../buttons/system-button.vue';

export default {
  components: {
    SystemButton,
    FacilityPhoto,
  },
  props: {
    facilityData: {
      type: Object,
      default: () => {},
    },
    isFavorited: Boolean,
    isClaimable: Boolean,
    isEditable: Boolean,
    isSummary: Boolean,
    claimed: Boolean,
    claiming: {
      type: Boolean,
      default: false,
    },
    claimDisabled: Boolean,
  },
  computed: {
    photoUrl() {
      return this.facilityData.titleImage || this.facilityData.titleGoogleImage?.photoUrl;
    },
    facilityId() {
      return this.facilityData.id;
    },
    claimText() {
      if (this.claiming) return 'Claiming';
      if (this.claimed) return '<i class="fal fa-check"></i> Claimed';

      return 'Claim';
    },
    buttonStyle() {
      if (this.claiming) return 'clear';
      return this.claimed ? 'secondary' : 'primary';
    },
    showClaimBtn() {
      return !this.isSummary || !this.claimed;
    },
    showUnclaimLink() {
      return this.isSummary && this.claimed;
    },
  },
};
</script>

<style lang="scss" scoped>
.simple-facility-card {
  display: grid;
  grid-template-columns: calc(var(--grid-unit) * 10) 1fr calc(var(--grid-unit) * 3);
  gap: calc(var(--grid-unit) * 2);
  padding-right: calc(var(--grid-unit) * 2);
  overflow: hidden;
  text-align: left;
  border: var(--border) var(--gray-80);
  border-radius: var(--radius-big);

  &__image {
    width: calc(var(--grid-unit) * 10);
    min-height: 68px;
    background-color: var(--viridian-10);
  }

  &__data {
    padding: calc(var(--grid-unit) * 2) 0;

    & > * {
      margin: 0;
    }
  }

  &__fav {
    align-self: center;
    font-size: calc(var(--grid-unit) * 3);
    color: var(--coral-20);
  }

  &__claim,
  &__edit {
    align-self: center;
    text-align: right;
  }

  &__claim {
    a {
      margin-right: calc(var(--grid-unit) * 1.5);
    }
  }

  &__edit {
    margin-right: calc(var(--grid-unit) * 1.5);

    a:first-of-type {
      margin-right: calc(var(--grid-unit) * 3);
    }
  }

  &--claimable {
    grid-template-columns: calc(var(--grid-unit) * 8) 1fr calc(var(--grid-unit) * 13.25);
    border: var(--border) var(--gray-20);
    box-shadow: 0 5px 10px 0 rgba(223, 223, 223, 0.75);

    .simple-facility-card__image {
      width: 60px;
    }
  }
}
</style>
