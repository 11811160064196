<template>
  <div class="login js-registration-form">
    <div class="login__content">
      <form id="registrationModal"
            data-track="signup_form" action="/users"
            accept-charset="UTF-8"
            data-remote="true"
            method="post"
            class="js-track-submit">
        <div class="full-name-row">
          <k-form-field
              label="First name"
              rules="required"
              class="k-form-field__name"
          >
            <k-input
                name="user[first_name]"
                v-model="firstName"
            />
          </k-form-field>
          <k-form-field
              label="Last name"
              rules="required"
              class="k-form-field__name"
          >
            <k-input
                name="user[last_name]"
                v-model="lastName"
            />
          </k-form-field>
        </div>
        <k-form-field
            label="Email"
            class="form-field"
            :rules="{email: true, required: true}"
        >
          <k-input
              name="user[email]"
              v-model.trim="email"
              class="w-100 js-sign-up-email"
              :ref="email"
          >
          </k-input>
        </k-form-field>
        <k-form-field
            label="Phone number"
            rules="required|mobileNumber"
            class="k-form-field__phone-number"
        >
          <k-input
              name="user[phone_number]"
              rules="required"
              v-model="phoneNumber"
              class="w-100"
          />
        </k-form-field>
        <k-form-field label="Password" class="form-field" :rules="'required'">
          <k-input
            name="user[password]"
            type="password"
            v-model.trim="password"
            class="w-100"
            :ref="password"
          >
          </k-input>
          <password-meter class="password-meter" :password="password" @score-changed="setScore"/>
        </k-form-field>
        <k-form-field label="Confirm password" class="form-field" :rules="'required'">
          <k-input
            name="user[password_confirmation]"
            v-model.trim="passwordConfirmation"
            class="form-field__input w-100"
            type="password"
            autocomplete="new-password"
          >
          </k-input>
          <small class="form-text text-muted">
            {{ confirmationHint }}
          </small>
        </k-form-field>
        <k-form-field>
          <label
            class="r-modal__label r-modal__label--checkbox r-modal__label--checkbox-terms"
          >
            <k-checkbox name="user[agree]" value="true" v-model="acceptCheckbox">
              <span id="terms_label"
                >I accept Kinside's
                <a href="/terms-of-service" target="_blank">Terms and Conditions</a>
              </span>
            </k-checkbox>
          </label>
        </k-form-field>
        <div class="js-errors"></div>
        <box color="rust" class="mt-2 mb-2" v-if="error.length > 0 || errorUnknown">
          <p>{{ error }}</p>
          <p v-if="errorUnknown">
            Something went wrong. Please contact us directly via
            <a href="mailto:support@kinside.com">support@kinside.com</a>
          </p>
        </box>

        <div class="text-center">
          <input name="recaptcha_token"
                  type="hidden"
                  id="recaptcha_token"
                  autocomplete="off"
          />
          <recaptcha action="user_registration"
                      btn-text="Create your free provider account"
                      class="button--cta js-sign-up-submit js-track-click"
                      data-track="provider_signup home modal"
                      :disabled="submitDisabled"
                      @token-set="submitForm"
                      id="submit-btn"/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { axios, regexPatterns } from '@utils';
import PasswordMeter from '@components/password-meter.vue';
import ConfirmationHint from '@mixins/confirmationHint';
import KFormField from '@components/forms/form_field.vue';
import KInput from '@components/inputs/input.vue';
import Recaptcha from '@components/recaptcha.vue';
import KCheckbox from '@components/inputs/checkbox.vue';
import Box from '@components/box.vue';

export default {
  name: 'provider-register',
  components: {
    PasswordMeter,
    KFormField,
    KInput,
    KCheckbox,
    Recaptcha,
    Box,
  },
  mixins: [ConfirmationHint],

  props: {
    ccfId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      password: '',
      passwordConfirmation: '',
      loading: false,
      acceptCheckbox: false,
      emailRegex: regexPatterns.email.regex,
      passwordScore: 0,
      error: '',
      errorUnknown: false,
    };
  },
  methods: {
    setScore(score) {
      this.passwordScore = score;
    },
    onModalClose() {
      this.email = '';
      this.phoneNumber = '';
      this.firstName = '';
      this.lastName = '';
      this.password = '';
      this.passwordConfirmation = '';
      this.loading = false;
      this.acceptCheckbox = false;
      this.error = '';
      this.errorUnknown = false;
      $('.error-message').empty();
    },
    submitForm() {
      this.errorUnknown = false;
      this.error = '';
      if (!this.loading) {
        this.loading = true;
        axios.post(
          '/users.json',
          {
            user: {
              email: this.email.toLowerCase(),
              password: this.password,
              password_confirmation: this.passwordConfirmation,
              provider_signup: 'true',
              agree: this.acceptCheckbox,
              first_name: this.firstName,
              last_name: this.lastName,
              phone_number: this.phoneNumber,
            },
            ccf_id: this.ccfId,
            signup_from: 'claim_profile',
            verify_account_required: 'true',
          },
        ).then((response) => {
          if (response.data.success && response.status === 200) {
            this.$emit('submit', { email: this.email.toLowerCase() });
          } else if (response.data.message.length > 0) {
            this.error = response.data.message;
          } else {
            this.errorUnknown = true;
          }
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          this.errorUnknown = true;
        });
      }
    },
  },
  computed: {
    submitDisabled() {
      return (
        this.firstName.length === 0
        || this.lastName.length === 0
        || this.email.length === 0
        || !this.emailRegex.test(this.email)
        || this.password.length === 0
        || this.passwordConfirmation.length === 0
        || this.acceptCheckbox === false
        || this.passwordScore < 5
        || this.password !== this.passwordConfirmation
      );
    },
  },
};
</script>

<style lang="scss">
  /* stylelint-disable-next-line selector-id-pattern */
  #registrationModal {
    .full-name-row {
      margin-top: calc(var(--grid-unit) * 3);
      margin-bottom: calc(var(--grid-unit) * 2);
      display: flex;

      .k-form-field__name {
        margin: 0 !important;
      }

      :first-child {
        padding-right: var(--grid-unit) !important;
      }
    }
  }
</style>
