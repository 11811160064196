<template>
  <div>
    <div class="verify-options" v-if="phoneNumber?.length">
      <k-radio :value="'sms'" name="selectedMethod" v-model="localVerifyMethod" inline>
        <div class="label-content">
          <div class="label-text">Text me</div>
          <div class="phone-number">
            at <span class="number">{{ phoneNumber }}</span>
            <a v-if= "enableEdit"
              href="#" class="change-link" @click.prevent="editPhone()"
            >
                Change
            </a>
          </div>
        </div>
      </k-radio>
    </div>
    <div class="verify-options" v-if="phoneNumber?.length">
      <k-radio :value="'call'" name="selectedMethod" v-model="localVerifyMethod" inline>
        <div class="label-content">
          <div class="label-text">Call me</div>
          <div class="phone-number">
            at <span class="number">{{ phoneNumber }}</span>
            <a v-if= "enableEdit"
              href="#" class="change-link" @click.prevent="editPhone()"
            >
                Change
            </a>
          </div>
        </div>
      </k-radio>
    </div>
    <div class="verify-options" v-if="email?.length">
      <k-radio :value="'email'" name="selectedMethod" v-model="localVerifyMethod" inline>
        <div class="label-content">
          <div class="label-text">Email me</div>
          <div class="phone-number">
            at <span class="number">{{ email }}</span>
          </div>
        </div>
      </k-radio>
    </div>
  </div>
</template>

<script>
import KRadio from '@components/inputs/radio.vue';

export default {
  name: 'verification-methods',
  components: {
    KRadio,
  },
  props: {
    phoneNumber: String,
    email: String,
    verifyMethod: String,
    enableEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localVerifyMethod: this.verifyMethod,
    };
  },
  watch: {
    localVerifyMethod(newStatus) {
      this.$emit('change', newStatus);
    },
  },
  methods: {
    editPhone() {
      this.$emit('edit-phone');
    },
  },
};
</script>

<style lang="scss" scoped>
  .verify-options {
    padding: calc(var(--grid-unit) * 2);
    border: var(--border) var(--gray-80);
    border-radius: var(--radius-big);
    display: flex;
    align-items: center;

    &:first-child {
      margin-top: 20px;
    }

    &:last-child {
      margin-bottom: 60px;
    }

    &+.verify-options {
      margin-top: 15px;
    }

    .label-content {
      .label-text {
        font-weight: 500;
        color: var(--gray-80);
      }

      .phone-number {
        font-size: 0.9em;
        color: #888;

        .change-link {
          color: #008066;
          text-decoration: none;
          margin-left: 5px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
</style>
