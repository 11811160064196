<template>
  <div>
    <k-form
        id="google-auth-form"
        :action="'/users/auth/google_oauth2'"
        method="POST"
        style="display: none;"
      >
        <!-- CSRF Token -->
        <k-input
          type="hidden"
          name="authenticity_token"
          :value="csrfToken"
        />
    </k-form>
    <system-button
      styleType="clear"
      class="login__content__google-sso w-100 mt-4 google-btn"
      @click="submitOAuthForm"
    >
      <div>
        <img
          src="https://res.cloudinary.com/kinside/image/upload/v1693276234/app_assets/google-logo.png"
          alt="Google Logo"
          class="mr-3 ml-3"
          />
        Sign in with Google
      </div>
    </system-button>
  </div>
</template>

<script>
import KForm from '@components/forms/form.vue';
import KInput from '@components/inputs/input.vue';
import SystemButton from '@components/buttons/system-button.vue';

export default {
  name: 'google-o-auth',
  components: {
    KForm,
    KInput,
    SystemButton,
  },
  data() {
    return {
      csrfToken: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    };
  },
  methods: {
    submitOAuthForm() {
      document.getElementById('google-auth-form').submit();
    },
  },
};
</script>

<style lang="scss" scoped>
.google-btn {
  border: 1px solid var(--gray-20);
  border-radius: 8px;

  &::v-deep .systemBtn__text {
    color: var(--gray-80);
    justify-content: unset;
  }
}
</style>
