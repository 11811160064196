<template>
  <div class="verification-container align-center">
    <p class="instructions">
      Please enter the code sent to:<br />
      <strong class="phone-number">{{ email || phoneNumber }}</strong>
    </p>
    <div class="mt-2 code-input">
      <vue-verification-code-input :fieldHeight="40" :fieldWidth="36"
                                ref='code'
                                :class="error ? 'error' : '' "  @complete="sendOutCode"
                                @change="sendOutCode"
                                v-model="verificationCode" :fields="5"/>
    </div>
    <p class="resend-link">
      Not seeing the code? <a href="#" @click.prevent="resendVerificationCode">Send again</a>
    </p>
  </div>
</template>

<script>
import VueVerificationCodeInput from 'vue-verification-code-input';

export default {
  name: 'verify-account',
  components: {
    VueVerificationCodeInput,
  },
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    email: String,
    phoneNumber: String,
    codeEntered: String,
  },
  data() {
    return {
      verificationCode: this.codeEntered,
    };
  },
  methods: {
    sendOutCode(value) {
      this.$emit('change', value);
      this.verificationCode = value;
    },
    resendVerificationCode() {
      this.$emit('resend-code');
    },
  },
};
</script>

<style lang="scss" scoped>
.verification-container {
  text-align: center;
  padding: 20px;

  .instructions {
    margin-bottom: 10px;
    font-size: 16px;

    .phone-number {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .resend-link {
    margin-top: 10px;
    font-size: 14px;
    color: #666;
  }

  .code-input {
    display: flex;
    justify-content: center;
  }
}
</style>
