import Vue from 'vue';
import { initSentry } from '@utils';
import store from '@care-provider/stores/care-provider.store';
import ProfileOnboarding from '../src/care-provider/components/profile-onboarding.vue';
import UserQuotes from '../src/static/components/user-quotes.vue';

document.addEventListener('DOMContentLoaded', () => {
  initSentry();

  // eslint-disable-next-line no-unused-vars
  const staticApp = new Vue({
    el: '#static_vue',
    store,
    components: {
      UserQuotes,
      ProfileOnboarding,
    },
  });
});
