<template>
  <section
    class="onboardingStep"
  >
    <h4 v-if="$slots.heading" class="onboardingStep__heading">
      <slot name="heading"></slot>
    </h4>
    <div :class="{'centered-text': centered}">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    centered: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.onboardingStep {
  > * {
    margin-bottom: calc(var(--grid-unit) * 2);

    @media screen and (min-width: 992px) {
      margin-bottom: calc(var(--grid-unit) * 3);
    }
  }

  &__heading {
    padding-top: calc(var(--grid-unit) * 2);
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
}

.centered-text {
  text-align: center;
}
</style>
