<template>
  <div>
    <div class='meter' :style="meterStyle"></div>
    <small class="form-text text-muted">
      {{ passwordHint }}
    </small>
  </div>
</template>

<script>

export default {
  name: 'password-meter',
  data() {
    return {
      length: false,
      uppercase: false,
      lowercase: false,
      number: false,
      symbol: false,
      meterStyle: '',
      passwordHint: 'Must be at least 8 characters long and include uppercase, lowercase, number and symbol.',
    };
  },
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  methods: {
    passwordScore() {
      const lowerCaseRegex = /^(?=.*[a-z]).+$/;
      const upperCaseRegex = /^(?=.*[A-Z]).+$/;
      const numberRegex = /^(?=.*\d).+$/;
      const symbolRegex = /^(?=.*[-+_!@#$%^&*.,?]).+$/;
      let score = 0;
      const hintIncludes = [];

      if (this.password.length >= 8) {
        this.length = true;
        score += 1;
      } else {
        this.length = false;
      }
      if (upperCaseRegex.test(this.password)) {
        this.uppercase = true;
        score += 1;
      } else {
        this.uppercase = false;
        hintIncludes.push('uppercase');
      }
      if (lowerCaseRegex.test(this.password)) {
        this.lowercase = true;
        score += 1;
      } else {
        this.lowercase = false;
        hintIncludes.push('lowercase');
      }
      if (numberRegex.test(this.password)) {
        this.number = true;
        score += 1;
      } else {
        this.number = false;
        hintIncludes.push('number');
      }
      if (symbolRegex.test(this.password)) {
        this.symbol = true;
        score += 1;
      } else {
        this.symbol = false;
        hintIncludes.push('symbol');
      }

      this.setMeterColor(score);

      this.setPasswordHint(score, hintIncludes);

      this.$emit('score-changed', score);
    },
    setMeterColor(score) {
      let color = 'var(--rust-30)';
      if (score === 5) {
        color = 'var(--viridian-30)';
      } else if (score >= 3) {
        color = 'var(--gold-30)';
      }
      this.meterStyle = `width: ${score * 20}%;background-color: ${color};`;
    },
    setPasswordHint(score, includes) {
      if (score < 5) {
        let message = !this.length ? 'Must be at least 8 characters long ' : 'Must ';
        const lastInclude = includes.pop();
        message += lastInclude ? ' and ' : '';
        if (includes.length > 0) {
          message += `include ${includes.join(', ')}, and ${lastInclude}`;
        } else if (lastInclude) {
          message += `include ${lastInclude}`;
        }

        this.passwordHint = message;
      } else {
        this.passwordHint = 'Nice password!';
      }
    },
  },
  watch: {
    password() {
      this.passwordScore();
    },
  },
};
</script>

<style lang="scss" scoped>
.meter {
  height: 4px;
  border-radius: 2px;
}
</style>
